import React, { Fragment } from 'react';
import './HomePage.css';

const HomePage = () => {
    return (
        <Fragment>
            <div id = "main">
                <div className='name'>
                    <span>rusu traian</span>
                    <span>cristian</span>
                </div>

                <div className='content'>
                    <div className='content-section'>
                        <div className='content-section-title'>code</div>
                        <div className='content-section-block'>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://github.com/RusuTraianCristian">GitHub</a></div>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://s3.us-west-2.amazonaws.com/rusutraiancristian.com/SER.pdf">resume</a></div>
                        </div>
                    </div>
                    <div className='content-section'>
                        <div className='content-section-title'>work</div>
                        <div className='content-section-block'>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://transit.expert">founder/CTO @transit.expert</a></div>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://ready.software">founder/CTO @ready.software</a></div>
                        </div>
                    </div>
                    <div className='content-section'>
                        <div className='content-section-title'>social</div>
                        <div className='content-section-block'>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/rusutraiancristian/">LinkedIn</a></div>
                            <div><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RusuTCristian">X</a></div>
                        </div>
                    </div>
                    <div className='content-section'>
                        <div className='content-section-title'>email</div>
                        <div className='content-section-block'>
                            <div><a target="_blank" rel="noopener noreferrer" href="mailto:rusutraiancristian@gmail.com">rusutraiancristian@gmail.com</a></div>
                        </div>
                    </div>
                </div>

                <div className='end-page'>
                    living above the API
                </div>
            </div>
        </Fragment>
    );
}

export default HomePage;